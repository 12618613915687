<template>
    <router-view v-if="isStandalone" />
	<div v-else-if="$user" class="main-layout" id="app" >
        <!-- <DevelopmentLabel v-if="canShowDevelopmentLabel"/> -->

        <MainNav v-once :nav-items="navItems" />
        <main class="main-content" :class="{'open-nav': wideNav}">
            <MainHeader v-once />

            <div class="page-content">
                <SidePanel v-if="sidePanelData" :panel-data="sidePanelData"/>
                <HelpSidePanel v-if="!$theme.isBranded"></HelpSidePanel>
                <transition name="toRight" mode="out-in">
                    <router-view :key="$route.params.id"/>
                </transition>
            </div>
            <button class="scroll-top" aria-label="Scroll to top" :class="{'visible': scrollTopVisible }" type="button" @click="scrollToTop" v-tooltip="'Scroll to top'">
                <BaseIcon name="caret_up" />
            </button>
        </main>
	</div>
</template>

<script>
import MainNav from "@/components/layouts/MainNav";
import MainHeader from "@/components/layouts/MainHeader";
import menuConfig from "@/utils/menuConfig";
// import DevelopmentLabel from '@/components/DevelopmentLabel.vue';
import {timeout} from '@/utils';
export default {
    components: { 
        MainNav, MainHeader,
        SidePanel: () => import('@/components/sidepanel/SidePanel.vue'), 
        HelpSidePanel: () => import("@/components/HelpSidePanel")
    },
    data() {
        return {
            wideNav: localStorage.getItem('wideNav') ? JSON.parse(localStorage.getItem('wideNav')) : true,
            scrollTopVisible: false,
            sidePanelData: null,
            // showGlobalAlert: !(localStorage.getItem('globalAlertDismissedOn'))
        }
    },
    mounted(){
        if(!this.$user) return;
        this.$eventHub.$on('open-nav', this.isOpen);

        if(this.isStandalone) return;
        this.$eventHub.$on('open-nav', this.isOpen);

        if(!this.$user.terms_conditions){

            this.$modals.open({
                id: 'dataAgreement',
                component: 'DataAgreement',
                hideHeader: true
            });

            this.$modals.open({
                id: 'tsla',
                component: 'TermsConditions',
                hideHeader: true
            });
        }

        window.addEventListener('offline', this.lostConnectionAlert);
        window.addEventListener('scroll', this.catchScroll, {passive: true});
        this.getSidePanel();
        
        if(this.$user.email === this.$user.username){
            this.$modals.open({
                id: 'emailPrompt',
                title: 'Please enter your email address',
                component: 'UserEmailPrompt',
                hideHeaderControls: true
            });
        }
    },
    computed: {
        isStandalone(){
            return this.$route.meta?.standalone;
        },
        navItems() {
            const menuItems = [];
			menuConfig.forEach(group => {
				const children = [];
				group.children.forEach(item => {
                    if(item.permission){
                        if(this.$user.can(item.permission)){
                            if(this.$theme.disabled_routes){
                                if(!this.$theme.disabled_routes.includes(item.routeName)){
                                    children.push(item);
                                }
                            }else{
                                children.push(item);
                            }
                        }
                    }else{
                        children.push(item);
                    }
					
				});

                group.children = children;
                
				if(children.length){
					menuItems.push(group);
				}
            });
        
            return menuItems;
        },
        canShowDevelopmentLabel(){
            const mode = process.env.NODE_ENV;
            if (mode === 'development' || mode === 'review') {
                return true;
            }
            return false;
        }
    },
	methods: {
        isOpen(wideNav){
            this.wideNav = wideNav;
        },
        lostConnectionAlert(event) {
            if(event.type == 'offline')
                this.$snack.show({text: 'The network connection has been lost.'});
        },
        catchScroll () {
            const pastTopOffset = window.pageYOffset > 300;
            this.scrollTopVisible = pastTopOffset;
        },
		scrollToTop(){
			window.scroll({
                top: 0, 
                left: 0, 
                behavior: 'smooth'
            });
        },
        async getSidePanel(){
            let route, component;
            if(['reports.single', 'reports.periodic.day', 'reports.periodic.week', 'reports.periodic.month'].includes(this.$route.name)){
                route = this.$route.name === 'reports.single' ? this.$route.matched[1] : this.$route.matched[2];
                await timeout(700); //transition time
                component = route?.components?.default;
            }
            else if (['statistics.connection-manager'].includes(this.$route.name)){
                route = this.$route.matched[1];
                await timeout(700); //transition time
                component = route?.instances?.default;
            }
            else{
                route = this.$route.matched[0];
                await timeout(700); //transition time
                component = route?.instances?.default;
            }
            if(component) {
                this.sidePanelData = component.tableData?.sidePanel || component.singleReportsTable?.sidePanel || component.dayReportsTable?.sidePanel || component.weekReportsTable?.sidePanel || component.monthReportsTable?.sidePanel;
            }
        },
        dismissAlert(){
            localStorage.setItem('globalAlertDismissedOn', new Date().getTime())
            this.showGlobalAlert = false;
        }
    },
    beforeDestroy() {        
        window.removeEventListener('scroll', this.catchScroll);
        this.$eventHub.$off('open-nav');
    },
    updated(){
        this.getSidePanel();
    }
};

</script>

<style lang="scss">
@import '@/assets/styles/style.scss';

.main-layout {
  display: grid;
  grid-template-columns: auto 1fr;
//   overflow: auto;
}
.main-content {
    padding-left: calc(2rem + 50px);
    padding-right: 2rem;
    min-width: 0;
    position: relative;
    &.open-nav{
        padding-left: calc(2rem + var(--navWidth));
    }
//   overflow: auto;
}
.page-content {
  padding-top: 0.5rem;
}

.scroll-top{
    position: fixed;
    bottom: 1rem;
    right: 3rem;
    user-select: none;
    font-size: 1em;
    border-radius: 2rem;
    padding: 0;
    height: 2.5rem;
    width: 2.5rem;
    border: 1px solid var(--mainColor);
    background: var(--bgColor);

    transform: translateY(2rem);
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transition: all 0.2s;

    span{
        display: block;
        transform: translate(0.55em, -3.5em) scale(3);
        height: 0;
        width: 0;
        pointer-events: none;
    }

    &.visible{
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
        z-index: 1;
    }
}

.global-alert-notification{
    display: inline-flex;
    width: calc(100% - 1.7rem);
    justify-content: space-between;
    align-items: center;
    margin-left: 0.5rem;
}
</style>